import React from "react";
import Image from "./assets/image.png";

import {
  ContactContainer,
  ContactContainerMobile,
  TextContainer,
  ImageContainer,
  Title,
  Line,
  Text,
  TextContent,
  OfficeImage,
  List,
  TextList,
} from "./styles";

const VolunteersParagraph = () => {
  return (
    <>
      <ContactContainer>
        <ImageContainer>
          <OfficeImage
            src={Image}
            alt="Hospice life volunteer playing chess with elderly patient"
          />
        </ImageContainer>
        <TextContainer>
          <TextContent>
            <Title>VOLUNTEERS</Title>
            <Line />
            <Text>
              Volunteers are a vital part of the Life Hospice Team. They help
              provide the compassionate support needed to our patients and their
              families during the last phases of life, in a variety of ways,
              including:
            </Text>
            <List>
              <TextList>
                Helping with administrative duties in the Life Hospice Office
              </TextList>
              <TextList>
                Providing patient and family support, including patient
                companionship and caregiver respite
              </TextList>
              <TextList>
                Supplementary Services, such as Reiki practitioners, Massage
                Therapists, Pet Therapy, and Hair Stylists (volunteers must be
                certified or licensed)
              </TextList>
            </List>
            <Text>
              Schedules and Assignments are flexible. Comprehensive training
              provided.
            </Text>
          </TextContent>
        </TextContainer>
      </ContactContainer>
      <ContactContainerMobile>
        <TextContainer>
          <TextContent>
            <Title>VOLUNTEERS</Title>
            <Line />
            <Text>
              Volunteers are a vital part of the Life Hospice Team. They help
              provide the compassionate support needed to our patients and their
              families during the last phases of life, in a variety of ways,
              including:
            </Text>
            <List>
              <TextList>
                Helping with administrative duties in the Life Hospice Office
              </TextList>
              <TextList>
                Providing patient and family support, including patient
                companionship and caregiver respite
              </TextList>
              <TextList>
                Supplementary Services, such as Reiki practitioners, Massage
                Therapists, Pet Therapy, and Hair Stylists (volunteers must be
                certified or licensed)
              </TextList>
            </List>
            <Text>
              Schedules and Assignments are flexible. Comprehensive training
              provided.
            </Text>
          </TextContent>
        </TextContainer>
        <ImageContainer>
          <OfficeImage
            src={Image}
            alt="Hospice life volunteer playing chess with elderly patient"
          />
        </ImageContainer>
      </ContactContainerMobile>
    </>
  );
};

export default VolunteersParagraph;
