import styled from "styled-components";

import { COLORS } from "../../styles/globals/colors";
import { TABLET_992, TABLET_800, MOBILE_460, DESKTOP_1440 } from "../../styles/globals/sizes";

export const ContactContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 1500px;
  width: 100%;
  margin: auto;

  @media (max-width: ${TABLET_992}px) {
    flex-direction: column;
    display: none;
  }
`;

export const ContactContainerMobile = styled.div`
  display: none;
  flex-direction: row;
  max-width: 1500px;
  width: 100%;
  margin: auto;

  @media (max-width: ${TABLET_992}px) {
    flex-direction: column;
    display: flex;
  }
`;

export const TextContainer = styled.div`
  width: 50%;
  background: #f2f2f2 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 0 20px;

  @media (max-width: ${TABLET_992}px) {
    width: 100%;
  }
  @media (max-width: ${MOBILE_460}px) {
    padding: 30px 0 50px;
  }
`;

export const TextContent = styled.div`
  width: 80%;

  @media (max-width: ${TABLET_992}px) {
    width: 90%;
  }
`;

export const ImageContainer = styled.div`
  width: 50%;
  max-height: 563px;

  @media (max-width: ${DESKTOP_1440}px) {
    max-height: fit-content;
  }

  @media (max-width: ${TABLET_992}px) {
    width: 100%;
    height: 401px;
  }
`;

export const Title = styled.h3`
  font: normal normal normal 37px/49px Roboto;
  color: #f170ae;

  @media (max-width: ${MOBILE_460}px) {
    font: normal normal normal 28px/40px Roboto;
  }
`;

export const Line = styled.div`
  width: 20%;
  border-bottom: 4px solid #f170ae;
  margin: 8px 0 20px;
`;

export const Text = styled.div`
  width: 86%;
  font: 400 18px/26px Roboto;
  color: ${COLORS.gray};
  margin-bottom: 15px;

  @media (max-width: ${TABLET_992}px) {
    width: 100%;
    margin-bottom: 10px;
  }
`;

export const OfficeImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;

  @media (max-width: ${TABLET_992}px) {
    object-position: top;
  }
`;

export const List = styled.ul`
  list-style: disc;
  margin-left: 20px;

  li::marker {
    color: ${COLORS.magenta};
  }
`;

export const TextList = styled.li`
  width: 87%;
  font: 400 18px/26px Roboto;
  color: ${COLORS.gray};
  align-items: center;
  margin-bottom: 15px;

  @media (max-width: ${TABLET_992}px) {
    width: 100%;
  }
`;
