import styled from "styled-components";

import { COLORS } from "../globals/colors";
import {
  DESKTOP_1920,
  DESKTOP_1440,
  DESKTOP_1366,
  DESKTOP_1200,
  DESKTOP_1024,
  TABLET_992,
  TABLET_800,
  TABLET_768,
  TABLET_740,
  MOBILE_460,
  MOBILE_380,
  MOBILE_320,
  TABLET_600,
} from "../globals/sizes";

export const HeroContainer = styled.div`
  margin: 90px auto 0 auto;
  object-fit: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1500px;
  width: 100%;
  overflow: hidden;
  background: #ECECEC;

  @media (max-width: ${DESKTOP_1024}px) {
      height: fit-content;
  }

  @media (max-width: ${TABLET_992}px) {
      background: white;
  }

  @media (max-width: ${MOBILE_460}px) {
    margin-top: 80px;
  }
`;

export const HeroPicture = styled.picture`
  @media (max-width: ${TABLET_600}px) {
    display: none;
  }
  & > img, 
  & > source {
    width: 100%;
    margin-right: 0%;
    // height: 100%;
    object-fit: cover;
  }
`

export const ContactUsPhone = styled.a`
  width: fit-content;
  padding: 14px 45px;
  border-radius: 25px;
  font: 400 18px/21px Roboto;
  color: ${COLORS.white};
  background-color: ${COLORS.magenta};
  border: 0;
  word-wrap: break-word;
  display: ${(props) =>  props.mobile ? "none" : "flex"};
  &:hover {
    filter: grayscale(50%);
  }
  @media (max-width: ${DESKTOP_1024}px) {
    font-size: 16px;
  }
  @media (max-width: ${TABLET_768}px) {
    align-self: center;
  }
  @media (max-width: ${MOBILE_460}px) {
    display: ${(props) =>  props.mobile ? "flex" : "none"};
    align-self: flex-start;
  }
`;

export const MobileDiv = styled.div`
  display: none;
  width: 100%;
  background: ${(props) =>
    props.bgImage
      ? `url(${props.bgImage}) 10% 45%/cover no-repeat`
      : `background: purple;`};
  @media (max-width: ${TABLET_600}px) {
    height: 340px;
    display: block;
    background-position: 55% 45%;
  }
`;

export const InternalWrapper = styled.div`
  margin: 0;
  position: absolute;
  width: 100%;
  max-width: 1500px;

  @media (max-width: ${DESKTOP_1200}px) {
    max-width: 90%;
  }
  @media (max-width: ${TABLET_992}px) {
    max-width: 100%;
    flex: 1;
    position: relative;
  }
  @media (max-width: ${MOBILE_460}px) {
    max-width: 100%;
  }

`;


export const HeroTextDiv = styled.div`
  padding: 50px 30px;
  box-sizing: border-box;
  margin: auto;
  
  @media (max-width: 1600px) {
    width: 90%;
  }
    
  @media (max-width: ${TABLET_992}px) {
    padding: 50px 40px;
    box-sizing: border-box;
  }
  @media (max-width: ${MOBILE_460}px) {
    margin: 30px 20px 10px;
    padding: 0;
    display: flex;
    flex-direction: column;
  }
  @media (max-width: ${MOBILE_320}px) {
    margin: 20px;
  }
`;

export const Title = styled.h1`
  width: 10em;
  margin: 40px 0 20px;
  color: ${COLORS.magenta};
  text-align: left;
  font: normal 57px/60px Roboto;
  text-transform: uppercase;

  & > .nowrap {
    white-space: nowrap;
  }

  @media (max-width: ${TABLET_992}px) {
    width: 100%;
    margin: 14px 0;
    text-align: center;
    align-items: center;
  }
  @media (max-width: ${MOBILE_460}px) {
    text-align: left;
    font: normal 47px/54px Roboto;
  }
`;

export const Paragraph = styled.p`
  margin: 10px 0 20px;
  color: ${COLORS.grayText};
  font: normal normal 26px/34px Roboto;
  width: 60%;
  @media (max-width: ${TABLET_992}px) {
    width: 100%;
    text-align: center;
    align-items: center;
  }
  @media (max-width: ${MOBILE_460}px) {
    text-align: left;
    font: normal normal 20px/28px Roboto;
  }
`;

export const AvailableText = styled.p`
  margin: 10px 0 20px;
  color: ${COLORS.grayText};
  font: normal normal 900 15px/20px Roboto;
  color: ${COLORS.magenta};
  width: 200px;
  text-align: center;

  @media (max-width: ${MOBILE_460}px) {
    width: 76%;
    text-align: left;
  }
`;

export const PhoneAndParagraph = styled.div`
  @media (max-width: ${TABLET_768}px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const TwoDivsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto;
  width: 100%;
  max-width: 1500px;
  @media (max-width: ${TABLET_800}px) {
    flex-direction: column;
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 55%;
  max-width: 1500px;
  padding: 40px 40px;

  @media (max-width: ${DESKTOP_1200}px) {
    width: 52%;
  }
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 55px;
  width: 45%;
  max-width: 1500px;
  margin-bottom: 0;
`;

export const Icon = styled.img`
  width: 23px;
  height: 22px;
  margin-right: 15px;
`;

export const Text = styled.div`
  width: 70%;
  color: ${COLORS.gray};
  font: 400 20px/28px Roboto;
  text-align: left;

  @media (max-width: ${DESKTOP_1200}px) {
    width: 100%;
    margin-left: 0;
    margin-top: 35px;
    font: 400 17px/25px Roboto;
  }
  @media (max-width: ${TABLET_800}px) {
    margin-top: 35px;
    font: 400 18px/26px Roboto;
    text-align: left;
  }
  @media (max-width: ${MOBILE_460}px) {
    margin-top: 10px;
    font: 400 17px/25px Roboto;
  }
`;

export const TextGetInTouch = styled.div`
  width: 100%;
  text-decoration: none;
  color: ${COLORS.gray};
  font: 400 20px/28px Roboto;
  text-align: left;

  @media (max-width: ${MOBILE_460}px) {
    margin-top: 0;
    color: ${COLORS.gray};
  }
`;

export const GetInTouchContainer = styled.div`
  width: 100%;
  max-width: 1500px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 50px 0;
  margin: auto;

  @media (max-width: ${DESKTOP_1366}px) {
    padding: 50px 0;
  }
  @media (max-width: ${DESKTOP_1200}px) {
    padding: 20px 0;
  }
  @media (max-width: ${TABLET_800}px) {
    width: 100%;
    height: fit-content;
    padding: 50px 0;
  }
  @media (max-width: ${MOBILE_460}px) {
    display: none;
  }
`;

export const GetInTouchContainerMobile = styled.div`
  width: 100%;
  max-width: 360px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  display: none;
  flex-direction: column;
  padding: 10px 0;
  margin: auto;

  @media (max-width: ${DESKTOP_1366}px) {
    padding: 50px 0;
  }
  @media (max-width: ${DESKTOP_1200}px) {
    padding: 20px 0;
  }
  @media (max-width: ${TABLET_800}px) {
    width: 100%;
    height: fit-content;
    padding: 40px 0;
  }
  @media (max-width: ${MOBILE_460}px) {
    display: flex;
  }
`;

export const Line = styled.div`
  width: 30%;
  border-bottom: 4px solid ${COLORS.magenta};
  margin: 20px 0;
`;

export const TextContainerOurTeam = styled.div`
  width: 100%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 0;
  margin: auto;

  @media (max-width: ${DESKTOP_1366}px) {
    padding: 50px 0;
  }
  @media (max-width: ${DESKTOP_1200}px) {
    padding: 20px 0;
  }
  @media (max-width: ${TABLET_800}px) {
    width: 100%;
    height: fit-content;
    padding: 50px 0;
  }
  @media (max-width: ${MOBILE_460}px) {
    padding: 50px 0;
  }
`;

export const OurTeamTitle = styled.h2`
  color: ${COLORS.magenta};
  //margin-bottom: 15px;
  font: 500 37px/43px Roboto;
  text-align: left;
  width: 100%;
  @media (max-width: ${DESKTOP_1200}px) {
    font: 500 35px/40px Roboto;
  }
  @media (max-width: ${DESKTOP_1024}px) {
    margin: 0 0 10px;
    font: 500 32px/35px Roboto;
  }
  @media (max-width: ${TABLET_800}px) {
    font: 500 37px/49px Roboto;
    text-align: left;
    width: 90%;
  }
  @media (max-width: ${MOBILE_460}px) {
    font: 500 30px/39px Roboto;
  }
`;

export const GetInTouchTitle = styled.h2`
  color: #F170AE;
  text-align: left;
  margin-bottom: 10px;
  width: 100%;
  font: 500 30px/39px Roboto;
`;


export const TitleService = styled.h2`
  color: ${COLORS.magenta};
  //margin-bottom: 15px;
  font: 500 37px/43px Roboto;
  text-align: center;
  width: 100%;
  @media (max-width: ${DESKTOP_1200}px) {
    font: 500 35px/40px Roboto;
  }
  @media (max-width: ${DESKTOP_1024}px) {
    margin: 0 0 10px;
    font: 500 32px/35px Roboto;
  }
  @media (max-width: ${TABLET_800}px) {
    font: 500 37px/49px Roboto;
    text-align: left;
    width: 90%;
  }
  @media (max-width: ${MOBILE_460}px) {
    font: 500 30px/39px Roboto;
    text-align: center;
  }
`;

export const ImageContainer = styled.div`
  height: 500px;
  width: 50%;
  @media (max-width: ${DESKTOP_1440}px) {
    height: 470px;
  }
  @media (max-width: ${DESKTOP_1200}px) {
    height: 350px;
  }
  @media (max-width: ${DESKTOP_1024}px) {
    height: 330px;
  }
  @media (max-width: ${TABLET_992}px) {
    height: 400px;
  }
  @media (max-width: 900px) {
    height: 390px;
  }
  @media (max-width: 850px) {
    height: 420px;
  }
  @media (max-width: ${TABLET_800}px) {
    width: 100%;
    height: 488px;
  }
  @media (max-width: ${TABLET_740}px) {
    height: 400px;
  }
  @media (max-width: 640px) {
    height: 350px;
  }
  @media (max-width: 550px) {
    height: 300px;
  }
  @media (max-width: ${MOBILE_460}px) {
    height: 250px;
  }
  @media (max-width: ${MOBILE_380}px) {
    height: 200px;
  }
  `;

export const ImageContent = styled.picture`
  & > img, 
  & > source {
    width: 100%;
    height: 100%;
    object-fit: cover;
    @media (max-width: ${DESKTOP_1200}px) {
      object-position: 20%;
    }
    @media (max-width: ${DESKTOP_1024}px) {
      object-position: 40%;
    }
    @media (max-width: ${TABLET_992}px) {
      object-position: 15%;
    }
    @media (max-width: 900px) {
      object-position: 25%;
    }
    @media (max-width: 850px) {
      object-position: 0%;
    }
  }
`


export const TextContainer = styled.div`
  width: 100%;
  background: transparent linear-gradient(259deg, #ED67A6 0%, #EC4746 100%) 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 70px 0;

  @media (max-width: ${DESKTOP_1366}px) {
    padding: 50px 0;
  }
  @media (max-width: ${DESKTOP_1200}px) {
    padding: 20px 0;
  }
  @media (max-width: ${TABLET_800}px) {
    width: 100%;
    height: fit-content;
    padding: 70px 0;
  }
  @media (max-width: ${MOBILE_460}px) {
    padding: 50px 0;
  }
`;

export const TextGetInTouchContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
  margin-top: 5px;

  @media (max-width: ${MOBILE_460}px) {
    margin-bottom: 0;
    align-items: flex-start;
    margin-top: 15px;
  }
`;

export const TextContent = styled.div`
  width: 80%;
  margin: auto 0;

  @media (max-width: ${TABLET_800}px) {
    width: 80%;
  }
`;

export const CardTitle = styled.div`
  color: ${COLORS.white};
  margin-bottom: 15px;
  font: bold 40px/50px Roboto;
  text-align: center;
  @media (max-width: ${DESKTOP_1200}px) {
    font: 400 35px/40px Roboto;
  }
  @media (max-width: ${DESKTOP_1024}px) {
    margin: 0 0 10px;
    font: 400 32px/35px Roboto;
  }
  @media (max-width: ${TABLET_800}px) {
    font: 400 37px/49px Roboto;
    text-align: left;
  }
  @media (max-width: ${MOBILE_460}px) {
    font: bold 30px/39px Roboto;
    width: 300px;
    text-align: center;
  }
`;

export const LineBreak = styled.br`
  @media (max-width: ${MOBILE_380}px) {
    display: none;
  }
`;

export const WhiteLine = styled.hr`
  width: 20%;
  min-width: 120px;
  height: 5px;
  float: left;
  background-color: ${COLORS.white};
  color: ${COLORS.white};
  @media (max-width: ${DESKTOP_1200}px) {
    height: 3px;
  }
  @media (max-width: ${MOBILE_460}px) {
    width: 150px;
  }
`;

export const CardParagraph = styled.div`
  width: 78%;
  color: ${COLORS.white};
  margin-top: 45px;
  margin-left: 115px;
  font: 400 20px/28px Roboto;
  text-align: center;

  @media (max-width: ${DESKTOP_1200}px) {
    width: 100%;
    margin-left: 0;
    margin-top: 35px;
    font: 400 17px/25px Roboto;
  }
  @media (max-width: ${TABLET_800}px) {
    margin-top: 25px;
    font: 400 18px/26px Roboto;
    text-align: center;
  }
`;

export const ExternalLink = styled.a`
  text-decoration: none;
  color: inherit;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

export const LineMobile = styled.div`
  width: 20%;
  border-bottom: 4px solid #F170AE;
  margin: 0 0 5px;
`;